import { AxiosResponse } from 'axios';
import { get, groupBy } from 'lodash';
import { push } from 'connected-react-router';
import axios from 'axios';
import { put, call, takeLatest, actionChannel, take, select, all } from 'redux-saga/effects';
import JSZip from 'jszip';
import Path from 'path-browserify';
import {
  ClaimsApi,
  ClaimDTO,
  ArbitrationsApi,
  ArbitrationDTO,
  ArbitrationDocumentsApi,
  ClaimWithCheckoutRelationsDTO,
} from '@reposit/api-client';
import { saveAs } from 'file-saver';
import { throttle } from 'lodash';

import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { createClaimsApi, runSagaWithAuth, createArbitrationsApi, createArbitrationDocumentsApi } from '../utils/api.utils';
import SCHEMA from '../schema';
import { getErrorMessage } from '../../utils/common.utils';
import { FlashState } from '../../components/FlashMessage/index';
import {
  CREATE_CLAIM_STORE_KEY,
  ClaimActionTypes,
  createClaimSuccess,
  createClaimFailed,
  CREATE_CLAIM_API_REQUESTED,
  fetchClaimSuccess,
  fetchClaimFailed,
  FETCH_CLAIM_API_REQUESTED,
  PUBLISH_CLAIM_API_REQUESTED,
  IMPROVE_CLAIM_API_REQUESTED,
  publishClaimFailed,
  publishClaimSuccess,
  improveClaimSuccess,
  improveClaimFailed,
  updateClaimFailed,
  updateClaimSuccess,
  UPDATE_CLAIM_API_REQUESTED,
  setCurrentClaimActionModal,
  PUBLISH_CLAIM_STORE_KEY,
  IMPROVE_CLAIM_STORE_KEY,
  UPDATE_CLAIM_STORE_KEY,
  approveClaimSuccess,
  approveClaimFailed,
  APPROVE_CLAIM_API_REQUESTED,
  APPROVE_CLAIM_STORE_KEY,
  DECLINE_CLAIM_STORE_KEY,
  declineClaimFailed,
  declineClaimSuccess,
  DECLINE_CLAIM_API_REQUESTED,
  REQUEST_CLAIM_EVIDENCES_STORE_KEY,
  requestClaimEvidencesFailed,
  requestClaimEvidencesSuccess,
  REQUEST_CLAIM_EVIDENCES_API_REQUEST,
  RESOLVE_CLAIM_API_REQUESTED,
  resolveClaimApiFailed,
  resolveClaimApiSuccess,
  RESOLVE_CLAIM_STORE_KEY,
  DEACTIVATE_CLAIM_API_REQUESTED,
  deactivateClaimApiSuccess,
  deactivateClaimApiFailed,
  DEACTIVATE_CLAIM_STORE_KEY,
  setPaidAtApiFailed,
  SET_PAID_AT_CLAIM_STORE_KEY,
  setPaidAtApiSuccess,
  SET_PAID_AT_CLAIM_API_REQUESTED,
  updateAutoChargeApiSuccess,
  updateAutoChargeApiFailed,
  UPDATE_AUTO_CHARGE_STORE_KEY,
  UPDATE_AUTO_CHARGE_API_REQUESTED,
  CREATE_ARBITRATION_API_REQUESTED,
  createArbitrationApiFailed,
  CREATE_ARBITRATION_STORE_KEY,
  createArbitrationApiSuccess,
  fetchClaimRequested,
  reopenClaimApiSuccess,
  REOPEN_CLAIM_STORE_KEY,
  reopenClaimApiFailed,
  REOPEN_CLAIM_API_REQUESTED,
  downloadDocumentsApiSuccess,
  DOWNLOAD_DOCUMENTS_STORE_KEY,
  downloadDocumentsApiFailed,
  DOWNLOAD_DOCUMENTS_API_REQUESTED,
  DOWNLOAD_DOCUMENTS_LOCALLY,
  setCurrentClaimZipDownloadZipProgress,
  setCurrentClaimZipDownloadTotal,
  incrementCurrentClaimZipDownloadCurrent,
  finishCurrentClaimZipDownload,
} from './claim.actions';
import { setFlashMessage, FlashMessagesActionTypes } from '../flash-messages/flash-messages.actions';
import {
  CreateClaimPayload,
  UpdateClaimPayload,
  ImproveClaimPayload,
  FetchClaimByIdPayload,
  PublishClaimPayload,
  RequestClaimEvidencesPayload,
  ResolveClaimApiRequestPayload,
  DeactivateClaimApiRequestPayload,
  SetPaidAtApiRequestPayload,
  UpdateAutoChargeApiRequestPayload,
  CreateArbitrationRequestPayload,
  ReopenClaimPayload,
} from './claim.types';
import { ClaimListActionTypes, clearFilters } from '../claim-list/claim-list.actions';
import { AppState } from '../root.reducer';
import { getFullClaimById } from '../selectors/claim.selectors';
import { getSupplierItemDocumentsByClaimId, getTenantItemDocumentsByClaimId } from '../selectors/mediation.selectors';
import { DocumentEntity } from '../entities/entities.types';
import { sagaMiddleware } from '../..';

export function* createClaim({ payload }: { type: string; payload: CreateClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.createClaim(payload)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(createClaimSuccess());
    const claimId = get(apiResponse.data, 'id');

    yield put(push(`/claims/${claimId}`));

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_STORE_KEY,
        message: 'Success! Draft Claim has been created.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_CLAIM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimActionTypes>(createClaimFailed(error));
  }
}

export function* fetchClaimById({ payload }: { type: string; payload: FetchClaimByIdPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.findClaimById(payload.claimId)));

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(fetchClaimSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(fetchClaimFailed(error));
  }
}

export function* updateClaim({ payload }: { type: string; payload: UpdateClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.updateClaim(payload.claimId, { details: payload.details }))
    );

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been updated.',
        state: FlashState.SUCCESS,
      })
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(updateClaimSuccess(apiResponse.data));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(updateClaimFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* publishClaim({ payload }: { type: string; payload: PublishClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.publishClaim(payload.claimId)));

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(publishClaimSuccess(apiResponse.data));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<ClaimListActionTypes>(clearFilters());
    yield put(push(`/claims`));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PUBLISH_CLAIM_STORE_KEY,
        message: 'Success! Claim has been published.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(publishClaimFailed(error));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PUBLISH_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* improveClaim({ payload }: { type: string; payload: ImproveClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.submitMoreInfoClaim(payload.claimId, { agentResponse: payload.response }))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<ClaimActionTypes>(improveClaimSuccess(apiResponse.data));
    yield put<ClaimListActionTypes>(clearFilters());
    yield put(push(`/claims`));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: IMPROVE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been improved.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(improveClaimFailed(error));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: IMPROVE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* approveClaim({ payload: claimId }: { type: string; payload: string }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.approveClaim(claimId)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(approveClaimSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: APPROVE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been approved.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<ClaimActionTypes>(approveClaimFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: APPROVE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* declineClaim({ payload: claimId }: { type: string; payload: string }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.declineClaim(claimId)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(declineClaimSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DECLINE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been declined.',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<ClaimActionTypes>(declineClaimFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DECLINE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* requestClaimEvidences({ payload }: { type: string; payload: RequestClaimEvidencesPayload }) {
  try {
    const { claimId, message } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.requestMoreInfoClaim(claimId, { message }))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(requestClaimEvidencesSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REQUEST_CLAIM_EVIDENCES_STORE_KEY,
        message: 'Success! Extra claim evidence has been requested',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<ClaimActionTypes>(requestClaimEvidencesFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REQUEST_CLAIM_EVIDENCES_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* resolveClaim({ payload }: { type: string; payload: ResolveClaimApiRequestPayload }) {
  try {
    const { claimId, reason, sendTenantNotifications, sendSupplierNotifications } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() =>
        claimsApi.resolveClaim(claimId, {
          reason,
          sendSupplierNotifications,
          sendTenantNotifications,
        })
      )
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(resolveClaimApiSuccess(apiResponse.data));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: RESOLVE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been resolved!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<ClaimActionTypes>(resolveClaimApiFailed(error));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: RESOLVE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* deactivateClaim({ payload }: { type: string; payload: DeactivateClaimApiRequestPayload }) {
  try {
    const { claimId, reason, sendSupplierNotifications, sendTenantNotifications } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.deactivateClaim(claimId, { reason, sendSupplierNotifications, sendTenantNotifications }))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(deactivateClaimApiSuccess(apiResponse.data));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DEACTIVATE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been deactivated!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<ClaimActionTypes>(deactivateClaimApiFailed(error));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DEACTIVATE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* setPaidAt({ payload }: { type: string; payload: SetPaidAtApiRequestPayload }) {
  try {
    const { claimId, paidAt } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.paySupplier(claimId, { paidAt })));

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(setPaidAtApiSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DEACTIVATE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been marked as paid!',
        state: FlashState.SUCCESS,
      })
    );
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(setPaidAtApiFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: SET_PAID_AT_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
  }
}

export function* updateAutoCharge({ payload }: { type: string; payload: UpdateAutoChargeApiRequestPayload }) {
  try {
    const { claimId, autoChargeEnabled } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.updateAutoCharge(claimId, { autoChargeEnabled }))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(updateAutoChargeApiSuccess());
    const message = payload.autoChargeEnabled ? 'Success! Auto Charge is enabled' : 'Success! Auto Charge is disabled';
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_AUTO_CHARGE_STORE_KEY,
        message,
        state: FlashState.SUCCESS,
      })
    );
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
  } catch (err) {
    const error = getErrorMessage(err);
    yield put(updateAutoChargeApiFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_AUTO_CHARGE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
  }
}

export function* createArbitration({ payload }: { type: string; payload: CreateArbitrationRequestPayload }) {
  try {
    const { claimId, arbitratedAmount, file } = payload;
    const arbitrationsApi: ArbitrationsApi = yield createArbitrationsApi();

    // Creates the arbitration first
    const response: AxiosResponse<ArbitrationDTO> = yield call(
      runSagaWithAuth(() =>
        arbitrationsApi.createArbitration({
          claimId,
          arbitratedAmount,
        })
      )
    );

    const arbitration = response.data;

    // Upload the document if there is one
    if (file && arbitration.id) {
      const arbitrationsDocumentApi: ArbitrationDocumentsApi = yield createArbitrationDocumentsApi();
      yield call(
        runSagaWithAuth(() =>
          arbitrationsDocumentApi.addAbitrationDocument(arbitration.id, file, file.name, 'ARBITRATION_SUMMARY')
        )
      );
    }

    if (arbitration.id) {
      yield call(runSagaWithAuth(() => arbitrationsApi.publishArbitration(arbitration.id)));
    }

    yield put(fetchClaimRequested({ claimId }));
    yield put(createArbitrationApiSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ARBITRATION_STORE_KEY,
        message: 'Success! Arbitration created!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ARBITRATION_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
    yield put(createArbitrationApiFailed(error));
  } finally {
    yield put(setCurrentClaimActionModal(''));
  }
}

export function* reopenClaim({ payload }: { type: string; payload: ReopenClaimPayload }) {
  try {
    const { claimId, outcomeDeadline } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();

    const response: AxiosResponse<ClaimWithCheckoutRelationsDTO> = yield call(
      runSagaWithAuth(() =>
        claimsApi.reopenClaim(claimId, {
          outcomeDeadline,
        })
      )
    );

    yield syncEntitiesAndGetResults(response.data, SCHEMA.claim);

    yield put(reopenClaimApiSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REOPEN_CLAIM_STORE_KEY,
        message: 'Success! Claim reopened!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: REOPEN_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
    yield put(reopenClaimApiFailed(error));
  } finally {
    yield put(setCurrentClaimActionModal(''));
  }
}

export function* downloadDocuments({ payload }: { type: string; payload: string }) {
  try {
    const claimId = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    yield call(runSagaWithAuth(() => claimsApi.zipDocuments(claimId)));

    yield put(downloadDocumentsApiSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DOWNLOAD_DOCUMENTS_STORE_KEY,
        message: 'Success! A link to the downloaded documents will be sent to Slack shortly!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (err) {
    const error = getErrorMessage(err);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DOWNLOAD_DOCUMENTS_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
    yield put(downloadDocumentsApiFailed(error));
  } finally {
    yield put(setCurrentClaimActionModal(''));
  }
}

function* incrementClaimDownloadProgress(claimId: string) {
  yield put(incrementCurrentClaimZipDownloadCurrent(claimId));
}

const download = (item: DocumentEntity, zip: JSZip, folder: string, claimId: string) => {
  //download single file as blob and add it to zip archive
  console.log(item.name);
  sagaMiddleware.run(incrementClaimDownloadProgress, claimId);

  return axios
    .get(item.url, {
      responseType: 'blob',
    })
    .then((resp) => {
      zip.file(`${claimId}/${folder}/${item.name}`, resp.data, {});
      console.log('done', item.name);
    });
};

function* downloadFiles(claimId: string, zip: JSZip, documents: DocumentEntity[], folder: string) {
  const groupedByName = groupBy(documents, 'name');
  const formattedDocs: DocumentEntity[] = Object.values(groupedByName).reduce((acc: DocumentEntity[], groupedDocs) => {
    if (groupedDocs.length === 1) return [...acc, ...groupedDocs];
    // greater than one means there's a duplicate
    const newDocs: DocumentEntity[] = groupedDocs.map((d, i) => {
      const name = Path.parse(d.name).name;
      const ext = Path.parse(d.name).ext;
      return { ...d, name: `${name}(${i + 1})${ext || ''}` } as DocumentEntity;
    });
    return [...acc, ...newDocs];
  }, []);

  yield all(formattedDocs.map((f) => call(() => download(f, zip, folder, claimId))));
}

function* fireAction(percent: number, claimId: string) {
  const params = { claimId, progress: Math.round(percent) };
  yield put(setCurrentClaimZipDownloadZipProgress(params));
}
const throttledFunc = throttle(fireAction, 200);

function* setZipProgress(percent: number, claimId: string) {
  yield throttledFunc(percent, claimId);
}

export function* downloadDocumentsLocally(claimId: string) {
  const zip = new JSZip();

  const claim: ClaimWithCheckoutRelationsDTO = yield select((state: AppState) => getFullClaimById(state, claimId));
  const supplierItemProposalDocs = yield select((state: AppState) => getSupplierItemDocumentsByClaimId(state, claimId));
  const tenantItemProposalDocs = yield select((state: AppState) => getTenantItemDocumentsByClaimId(state, claimId));

  // supplier
  const claimDocs = claim.documents.map((cd) => cd.document);
  const supplierItemProposalDocuments = supplierItemProposalDocs || [];
  const supplierDocs = [...claimDocs, ...supplierItemProposalDocuments];
  // arbitration
  const arbDocs = claim.arbitration && claim.arbitration.documents ? claim.arbitration.documents.map((ad) => ad.document) : [];
  // tenant
  const claimResponseDocuments = claim.responseDocuments ? claim.responseDocuments.map((rd) => rd.document) : [];
  const tenantItemProposalDocuments = tenantItemProposalDocs || [];
  const tenantDocs = [...claimResponseDocuments, ...tenantItemProposalDocuments];

  const totalDocLength = [...supplierDocs, ...arbDocs, ...tenantDocs].length;
  yield put(setCurrentClaimZipDownloadTotal({ total: totalDocLength, claimId }));

  yield call(() => downloadFiles(claim.id, zip, supplierDocs, 'supplier'));
  yield call(() => downloadFiles(claim.id, zip, arbDocs, 'arbitration'));
  yield call(() => downloadFiles(claim.id, zip, tenantDocs, 'tenant'));

  const blob = yield call(() =>
    zip.generateAsync({ type: 'blob', streamFiles: true }, (metadata) => {
      // found this on SO
      // not sure if its the best pattern but i couldn't get the action to be dispatched without it
      sagaMiddleware.run(setZipProgress, metadata.percent, claimId);
    })
  );
  saveAs(blob, `${claim.id}.zip`);
  yield put(finishCurrentClaimZipDownload(claimId));
}

// ****************
// WATCHERS
// ****************
export function* watchClaimsSagas() {
  yield takeLatest(CREATE_CLAIM_API_REQUESTED, createClaim);
  yield takeLatest(FETCH_CLAIM_API_REQUESTED, fetchClaimById);
  yield takeLatest(UPDATE_CLAIM_API_REQUESTED, updateClaim);
  yield takeLatest(PUBLISH_CLAIM_API_REQUESTED, publishClaim);
  yield takeLatest(IMPROVE_CLAIM_API_REQUESTED, improveClaim);
  yield takeLatest(APPROVE_CLAIM_API_REQUESTED, approveClaim);
  yield takeLatest(DECLINE_CLAIM_API_REQUESTED, declineClaim);
  yield takeLatest(REQUEST_CLAIM_EVIDENCES_API_REQUEST, requestClaimEvidences);
  yield takeLatest(RESOLVE_CLAIM_API_REQUESTED, resolveClaim);
  yield takeLatest(DEACTIVATE_CLAIM_API_REQUESTED, deactivateClaim);
  yield takeLatest(SET_PAID_AT_CLAIM_API_REQUESTED, setPaidAt);
  yield takeLatest(UPDATE_AUTO_CHARGE_API_REQUESTED, updateAutoCharge);
  yield takeLatest(CREATE_ARBITRATION_API_REQUESTED, createArbitration);
  yield takeLatest(REOPEN_CLAIM_API_REQUESTED, reopenClaim);
  yield takeLatest(DOWNLOAD_DOCUMENTS_API_REQUESTED, downloadDocuments);
}

export function* watchDownloads() {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(DOWNLOAD_DOCUMENTS_LOCALLY);
  while (true) {
    // 2- take from the channel
    const { payload } = yield take(requestChan);

    // 3- Note that we're using a blocking call
    yield call(downloadDocumentsLocally, payload);
  }
}
