import { ClaimItemDTO, ClaimProposalDTO } from '@reposit/api-client';
import React from 'react';
import RepositCard from '../Reposit/RepositCard';
import { Table, Tbody, Td, Thead, Tr } from '../Table';
import IndividualItem from './IndividualItem';

interface ClaimItemsProps {
  title: string;
  showDeletedAt?: boolean;
  items: ClaimItemDTO[];
  proposals: ClaimProposalDTO[];
}

const ClaimItems: React.FC<ClaimItemsProps> = ({ title, items, showDeletedAt = false, proposals }) => {
  return (
    <RepositCard flush title={title} tooltip="lorem">
      <Table>
        <Thead>
          <Tr>
            <Td>Id</Td>
            <Td>Description</Td>
            <Td>Type</Td>
            <Td>Amount</Td>
            <Td>Evidence</Td>
            {showDeletedAt && <Td>Deleted At</Td>}
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item) => (
            <IndividualItem item={item} showDeletedAt={showDeletedAt} proposals={proposals} />
          ))}
        </Tbody>
      </Table>
    </RepositCard>
  );
};

export default ClaimItems;
