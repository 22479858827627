import { UserDTOInternalRolesEnum } from '@reposit/api-client';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../components/Button';
import Filter from '../../components/Filter';
import FlashMessage from '../../components/FlashMessage';
import InlineTable from '../../components/InlineTable';
import { FullPageLoader } from '../../components/Loading';
import { OrganizationInviteModal } from '../../components/OrganizationInviteModal';
import RepositCard from '../../components/Reposit/RepositCard';
import SecondaryPanel from '../../components/SecondaryPanel';
import { Header1, P1 } from '../../components/Typography';
import { useIntegrators } from '../../hooks/use-integrators';
import { Option } from '../../interfaces/option.interface';
import { hasInternalRole } from '../../redux/account/account.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { setCurrentModal } from '../../redux/modal/modal.actions';
import { getCurrentModal } from '../../redux/modal/modal.selectors';
import { fetchOrganizationRecipientRequested } from '../../redux/organization-recipient/organization-recipient.actions';
import {
  CREATE_ORGANIZATION_INVITE_STORE_KEY,
  fetchOrganizationByIdRequested,
  FETCH_ORGANIZATION_BY_ID_STORE_KEY,
  UPDATE_ORG_MEDIATION_SETTINGS,
  updateOrganizationMediationSettingsRequested,
} from '../../redux/organization/organization.actions';
import { getOrganizationById } from '../../redux/organization/organization.selectors';
import { AppState } from '../../redux/root.reducer';
import { formatIntegratorOptions, getDisplayAddress } from '../../utils/common.utils';
import ClaimListContainer from '../Claim/List';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage';
import OrganizationBankAccounts from '../OrganisationBankAccounts';
import OrganizationNotes from '../OrganisationNotes';
import { OrganizationAccount } from '../OrganizationAccount';
import OrganizationInvites from '../OrganizationInvites';
import OrganizationRecipients from '../OrganizationRecipients';
import OrganizationUsers from '../OrganizationUsers';
import RepositListContainer from '../Reposit/List';
import ConfirmationModal from '../../components/ConfirmationModal';
interface OrganisationProps {
  match: any;
}

interface OrgDetailsItem {
  key: string;
  value: string;
}

const Address = styled(P1)`
  align-items: center;
  display: flex;
`;

const ActionButton = styled(Button)`
  margin-left: 10px;
`;

const renderContent = (currentListShowing: string, orgId: string, integratorOptions: Option[]): JSX.Element | null => {
  switch (currentListShowing) {
    case 'REPOSITS':
      return <RepositListContainer organizationId={orgId} />;

    case 'CLAIMS':
      return <ClaimListContainer organizationId={orgId} />;

    case 'USERS':
      return <OrganizationUsers organizationId={orgId} integratorOptions={integratorOptions} />;

    case 'INVITES':
      return <OrganizationInvites organizationId={orgId} />;

    case 'RECIPIENTS':
      return <OrganizationRecipients organizationId={orgId} />;
    case 'NOTES':
      return <OrganizationNotes organizationId={orgId} />;
    case 'BANK ACCOUNTS':
      return <OrganizationBankAccounts organizationId={orgId} />;
    case 'ACCOUNT':
      return <OrganizationAccount organizationId={orgId} />;
    default:
      return null;
  }
};

const CREATE_ORGANIZATION_INVITE_MODAL_TYPE = 'CREATE_ORGANIZATION_INVITE';
const UPDATE_ORGANIZATION_MEDIATION_SETTING_MODAL_TYPE = 'UPDATE_ORGANIZATION_MEDIATION_SETTING';

const standardOptions = ['REPOSITS', 'CLAIMS', 'USERS', 'INVITES', 'RECIPIENTS', 'NOTES', 'ACCOUNT'];

const getOptions = (hasOrganizationBankAccountsRole: boolean): string[] => {
  if (hasOrganizationBankAccountsRole) {
    return standardOptions.concat('BANK ACCOUNTS');
  } else {
    return standardOptions;
  }
};

const Organisation: React.FC<OrganisationProps> = ({ match }) => {
  const dispatch = useDispatch();
  const { isLoading: areIntegratorsLoading, integrators } = useIntegrators();
  const integratorOptions = formatIntegratorOptions(integrators);
  const [currentListShowing, setCurrentListShowing] = useState('REPOSITS');
  const orgId = match.params.id;
  const organization = useSelector((state: AppState) => getOrganizationById(state, orgId));

  const organizationLoadingSelector = createLoadingSelector([FETCH_ORGANIZATION_BY_ID_STORE_KEY]);
  const isOrgLoading = useSelector(organizationLoadingSelector);
  const currentModal = useSelector(getCurrentModal);

  const inviteSendingSelector = createLoadingSelector([CREATE_ORGANIZATION_INVITE_STORE_KEY]);
  const isInviteLoading = useSelector(inviteSendingSelector);

  const hasBankAccountsRole = useSelector((state: AppState) =>
    hasInternalRole(state, UserDTOInternalRolesEnum.FINANCEINDIVIDUAL)
  );
  const isLoading = isOrgLoading || areIntegratorsLoading || !integrators.length;
  useEffect(() => {
    dispatch(fetchOrganizationByIdRequested(orgId));
    dispatch(fetchOrganizationRecipientRequested({ organizationId: orgId }));
    return function resetOrganizationInviteModal() {
      dispatch(setCurrentModal(''));
    };
  }, [dispatch, orgId]);

  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([UPDATE_ORG_MEDIATION_SETTINGS]);

  if (isLoading || !organization) return <FullPageLoader />;

  const { name, attributes, legacyId, settings } = organization;
  const mediationEnabled = get(settings, 'mediationEnabled', false);
  const hubspotId = get(attributes, 'hubspotId', '');

  const toggleMediationEnabled = () => {
    dispatch(updateOrganizationMediationSettingsRequested({ organizationId: orgId, mediationEnabled: !mediationEnabled }));
    dispatch(setCurrentModal(''));
  };
  const orgDetailsItems: OrgDetailsItem[] = [{ key: 'Mediation', value: mediationEnabled ? 'Enabled' : 'Disabled' }];
  if (legacyId) orgDetailsItems.push({ key: 'Legacy Id', value: legacyId });
  if (hubspotId) orgDetailsItems.push({ key: 'Hubspot Id', value: hubspotId });

  return (
    <Fragment>
      {currentModal === CREATE_ORGANIZATION_INVITE_MODAL_TYPE ? (
        <OrganizationInviteModal
          onDismiss={() => dispatch(setCurrentModal(''))}
          organizationId={orgId}
          integratorOptions={integratorOptions}
          isInviteLoading={isInviteLoading}
        />
      ) : null}
      {currentModal === UPDATE_ORGANIZATION_MEDIATION_SETTING_MODAL_TYPE ? (
        <ConfirmationModal
          title={`${mediationEnabled ? 'Disable' : 'Enable'} mediation for ${organization.name}?`}
          text={`This will cause all claims created after the switch to use the ${
            mediationEnabled ? 'legacy end of tenancy process.' : 'mediation feature.'
          } This can be changed at any time.`}
          onDismiss={() => dispatch(setCurrentModal(''))}
          onSubmit={toggleMediationEnabled}
          loadingActions={[UPDATE_ORG_MEDIATION_SETTINGS]}
        />
      ) : null}
      <Container>
        <Row>
          <Col sm={12}>
            <Header1>{name}</Header1>
            {attributes && attributes.address && <Address>{getDisplayAddress(attributes.address)}</Address>}
          </Col>
        </Row>
        {/* For now, only displaying the table if the legacy id is filled */}
        {orgDetailsItems.length && (
          <Row>
            <Col lg={12}>
              <RepositCard
                title="Organisation Details"
                flashMessage={
                  flashMessagePayload ? (
                    <FlashMessage
                      onDismiss={dismissFlashMessage}
                      payload={flashMessagePayload}
                      timeRemaining={FLASH_MESSAGE_TIMEOUT}
                    />
                  ) : undefined
                }
              >
                <Container>
                  <Row>
                    <Col lg={12} style={{ padding: 0 }}>
                      <InlineTable items={orgDetailsItems} />
                    </Col>
                  </Row>
                </Container>
              </RepositCard>
            </Col>
          </Row>
        )}
      </Container>
      <Container style={{ marginBottom: 24 }}>
        <Row>
          <Col lg={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <ActionButton
                onClick={() => dispatch(setCurrentModal(UPDATE_ORGANIZATION_MEDIATION_SETTING_MODAL_TYPE))}
                buttonType={mediationEnabled ? 'warning' : 'primary'}
              >
                {mediationEnabled ? 'Disable Mediation' : 'Enable Mediation'}
              </ActionButton>
              <ActionButton onClick={() => dispatch(setCurrentModal(CREATE_ORGANIZATION_INVITE_MODAL_TYPE))}>
                Invite user
              </ActionButton>
            </div>
            <SecondaryPanel style={{ background: '#E7E4F1' }}>
              <Filter
                label="Showing"
                onSelect={setCurrentListShowing}
                selected={currentListShowing}
                options={getOptions(hasBankAccountsRole)}
              />
            </SecondaryPanel>
          </Col>
        </Row>
      </Container>
      <div style={{ padding: 24 }}>{renderContent(currentListShowing, orgId, integratorOptions)}</div>
    </Fragment>
  );
};

export default Organisation;
