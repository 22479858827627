import { TenancyOrderNoteDTO } from '@reposit/api-client/dist';
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { TenancyOrderWithActions } from '../../../redux/selectors';
import { Container } from '../../CollapsableContent/styles';
import RepositCard from '../RepositCard';
import IndividualNote from './IndividualNote';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../redux/selectors/user.selectors';

interface RepositNotesProps {
  tenancyOrder: TenancyOrderWithActions;
}

const sortByDate = (note1: TenancyOrderNoteDTO, note2: TenancyOrderNoteDTO) => {
  const date1 = new Date(note1.createdAt);
  const date2 = new Date(note2.createdAt);
  return moment(date1).unix() - moment(date2).unix();
};

const RepositNotes: React.FC<RepositNotesProps> = ({ tenancyOrder }) => {
  const notes = tenancyOrder.tenancyOrderNotes;
  const currentUser = useSelector(getCurrentUser);
  return (
    <RepositCard title="Reposit Notes">
      <Container>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            {notes.sort(sortByDate).map((item) => (
              <IndividualNote note={item} currentUser={currentUser} />
            ))}
          </Col>
        </Row>
      </Container>
    </RepositCard>
  );
};

export default RepositNotes;
