import { createSelector } from 'reselect';
import { get } from 'lodash';
import { OrganizationDTO } from '@reposit/api-client';

import { getTenancyOrder, TenantWithActions } from './tenancyOrder.selectors';
import { getClaims } from './claim.selectors';

export interface RepositSummaryData {
  id: string;
  tenancyId: string;
  dueDate?: string;
  claimId?: string;
  checkoutId?: string;
  status: string;
  address?: any;
  landlord?: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  deletedAt?: string;
  completedAt?: Date;
  tenants: TenantWithActions[];
  organization: OrganizationDTO;
}

export const makeGetRepositSummary = () => {
  return createSelector([getTenancyOrder, getClaims], (tenancyOrder, claims): RepositSummaryData | undefined => {
    if (!tenancyOrder) return undefined;
    const tenancy = get(tenancyOrder, 'tenancy');
    const tenancyId = get(tenancy, 'id');
    const checkoutId = get(tenancy, 'checkout.id');
    const property = get(tenancy, 'property');
    const landlord = get(property, 'landlord');
    const address = get(property, 'address');
    const endDate = get(tenancy, 'lastEndDate') as string;
    const deletedAt = get(tenancyOrder, 'deletedAt');
    const completedAt = get(tenancyOrder, 'tenancy.completedAt');
    const tenants = get(tenancyOrder, 'tenancy.tenants');
    const organization = get(tenancyOrder, 'tenancy.organization');

    let claim;
    if (tenancy.checkout && tenancy.checkout.claim) {
      claim = claims[tenancy.checkout.claim.id];
    }

    let ll;
    if (landlord) {
      ll = landlord.companyName ? landlord.companyName : `${landlord.firstName} ${landlord.lastName}`;
    }

    return {
      id: tenancyOrder.id,
      tenancyId,
      checkoutId,
      dueDate: tenancy.outcomeDeadline,
      claimId: claim && claim.id,
      status: tenancyOrder.order.statusId,
      address,
      startDate: tenancy.startDate,
      endDate,
      landlord: ll,
      createdAt: tenancyOrder.createdAt,
      deletedAt,
      completedAt,
      tenants,
      organization,
    };
  });
};

export default makeGetRepositSummary;
