import React from 'react';
import styled from 'styled-components';

interface RepositIntegratorProps {
  integratorName: string;
}

const Container = styled.div`
  background: ${(props) => props.theme.colors.positive};
  border-radius: 24px;
  color: #fff;
  font-family: ${(props) => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  padding: 6px 20px;
  text-align: center;
`;

const RepositIntegrator: React.FC<RepositIntegratorProps> = ({ integratorName }) => {
  return <Container>{integratorName}</Container>;
};

export default RepositIntegrator;
