import { AppState } from '../root.reducer';

export const getOrganizationUserIds = (state: AppState) => state.user.organizationUsers;
export const getOrganizationUsers = (state: AppState) =>
  state.user.organizationUsers.map((userId) => state.entities.user[userId]);
export const getHaveCurrentOrganizationUsersBeenFetched = (state: AppState) => state.user.haveCurrentOrganizationUsersBeenFetched;

export const getOrganizationUsersByUserId = (state: AppState, userId: string) => {
  const allOrgUsers = Object.values(state.entities.organizationUser);
  const orgUsersByUserId = allOrgUsers.filter((ou) => ou.userId === userId);
  return orgUsersByUserId.map((ou) => {
    return {
      ...ou,
      user: state.entities.user[userId],
      organization: state.entities.organization[ou.organizationId],
    };
  });
};
