import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createFlashMessagesSelector,
  createFailedFlashMessagesSelector,
} from '../../redux/flash-messages/flash-messages.selector';
import { dismissFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import styled from 'styled-components';
import FlashMessage from '../../components/FlashMessage/index';
import {
  DISCARD_REPOSIT_STORE_KEY,
  PUBLISH_REPOSIT_STORE_KEY,
  CHECKOUT_REPOSIT_STORE_KEY,
  EXTEND_REPOSIT_STORE_KEY,
  RESEND_TENANT_INVITE_STORE_KEY,
} from '../../redux/reposit/reposit.actions';
import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';
import {
  RESOLVE_CLAIM_STORE_KEY,
  DEACTIVATE_CLAIM_STORE_KEY,
  UPDATE_AUTO_CHARGE_STORE_KEY,
} from '../../redux/claim/claim.actions';
import {
  CREATE_ORGANIZATION_INVITE_STORE_KEY,
  CREATE_ORGANIZATION_STORE_KEY,
} from '../../redux/organization/organization.actions';
import { NOTIFY_INTEGRATOR_STORE_KEY } from '../../redux/integrator/integrator.actions';
import { RESET_USER_PASSWORD_STORE_KEY } from '../../redux/user/user.actions';

interface FlashMessageContainerProps {}

const subscribedMessages: string[] = [
  DISCARD_REPOSIT_STORE_KEY,
  PUBLISH_REPOSIT_STORE_KEY,
  CHECKOUT_REPOSIT_STORE_KEY,
  EXTEND_REPOSIT_STORE_KEY,
  RESEND_TENANT_INVITE_STORE_KEY,
  RESOLVE_CLAIM_STORE_KEY,
  DEACTIVATE_CLAIM_STORE_KEY,
  UPDATE_AUTO_CHARGE_STORE_KEY,
  CREATE_ORGANIZATION_INVITE_STORE_KEY,
  CREATE_ORGANIZATION_STORE_KEY,
  NOTIFY_INTEGRATOR_STORE_KEY,
  RESET_USER_PASSWORD_STORE_KEY,
];

const StyledFlashMessageContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  margin: 24px 0 0 240px;
  padding: 0 50px;
  width: calc(100% - 240px);
  z-index: 9000;
`;

export const FLASH_MESSAGE_TIMEOUT = 5000;

export const useFlashMessage = (storeKeys: string[]): [FlashMessagePayload, () => void] => {
  const dispatch = useDispatch();
  const getFlashMessageSelector = createFlashMessagesSelector(storeKeys);
  const flashMessage = useSelector(getFlashMessageSelector);
  const onDismissFlashMessage = () => dispatch(dismissFlashMessage(storeKeys));

  return [flashMessage, onDismissFlashMessage];
};

export const useFailedFlashMessage = (storeKeys: string[]): [FlashMessagePayload, () => void] => {
  const dispatch = useDispatch();
  const getFlashMessageSelector = createFailedFlashMessagesSelector(storeKeys);
  const flashMessage = useSelector(getFlashMessageSelector);
  const onDismissFlashMessage = () => dispatch(dismissFlashMessage(storeKeys));

  return [flashMessage, onDismissFlashMessage];
};

const FlashMessageContainer: React.FC<FlashMessageContainerProps> = (props) => {
  const [flashMessage, onDismissFlashMessage] = useFlashMessage(subscribedMessages);
  return flashMessage ? (
    <StyledFlashMessageContainer>
      <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessage} />
    </StyledFlashMessageContainer>
  ) : null;
};

export default FlashMessageContainer;
