import { ReferencesApi, SearchReferencesResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createReferencesApi, runSagaWithAuth } from '../utils/api.utils';
import {
  FETCH_REFERENCES_API_REQUESTED,
  ReferencingListActionTypes,
  fetchReferencesSuccess,
  fetchReferencesFailed
} from './reference-list.actions';
import { ReferenceListFilters } from './reference-list.types';
import { getReferenceListFilters, getReferenceListPagination } from './reference-list.selectors';

// ****************
// WORKERS
// ****************

export const REFERENCING_LIST_PAGE_SIZE = 10;

export function* fetchReferences() {
  try {
    const filters: ReferenceListFilters = yield select(getReferenceListFilters);
    const pagination = yield select(getReferenceListPagination);
    const referencesApi: ReferencesApi = yield createReferencesApi();

    const apiResponse: AxiosResponse<SearchReferencesResultsDTO> = yield call(
      runSagaWithAuth(() =>
        referencesApi.searchReferences(filters.filter, pagination.page, REFERENCING_LIST_PAGE_SIZE, filters.sort)
      )
    );

    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.references);
    yield put<ReferencingListActionTypes>(fetchReferencesSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<ReferencingListActionTypes>(fetchReferencesFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchReferenceListSagas() {
  yield takeLatest(FETCH_REFERENCES_API_REQUESTED, fetchReferences);
}
