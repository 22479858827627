import { PaymentPlanDTO, PaymentPlanInstalmentDTOStatusEnum } from '@reposit/api-client';
import { sortBy, startCase } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../containers/FlashMessage';
import { CREATE_PAYMENT_PLAN_STORE_KEY } from '../../redux/payment-plan/payment-plan.actions';
import { penceToPounds } from '../../utils/common.utils';
import Button from '../Button';
import FlashMessage from '../FlashMessage';
import InlineTable from '../InlineTable';
import Modal from '../Modal';
import RepositCard from '../Reposit/RepositCard';
import { Table, Tbody, Td, Thead, Tr } from '../Table';
import { Header3 } from '../Typography';

interface PaymentPlanProps {
  paymentPlan: PaymentPlanDTO;
}

interface PaymentPlanModalProps {
  paymentPlan: PaymentPlanDTO;
  onDismiss: () => void;
}

const ActionButton = styled(Button)`
  margin-left: auto;
`;

const formatISOStringDate = (date: string) => moment(date).format('DD-MM-YYYY');
const formatDate = (date: string) => moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');

const PaymentPlanModal: React.SFC<PaymentPlanModalProps> = ({ paymentPlan, onDismiss }) => {
  const { instalments } = paymentPlan;
  const sortedInstalments = sortBy(instalments, (i) => i.dueDate);
  return (
    <Modal onDismiss={onDismiss}>
      <Header3 style={{ marginBottom: 15 }}>Payment Plan Schedule</Header3>
      <Table>
        <Thead>
          <Tr>
            <Td>Number</Td>
            <Td>Due Date</Td>
            <Td>Amount</Td>
            <Td>Status</Td>
          </Tr>
        </Thead>
        <Tbody>
          {sortedInstalments &&
            sortedInstalments.length > 0 &&
            sortedInstalments.map((instalment, i) => (
              <Tr key={instalment.id}>
                <Td>{i + 1}</Td>
                <Td>{formatDate(instalment.dueDate)}</Td>
                <Td>£{penceToPounds(instalment.amount)}</Td>
                <Td>{startCase(instalment.status)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Modal>
  );
};

export const PaymentPlan: React.SFC<PaymentPlanProps> = ({ paymentPlan }) => {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const { createdAt, instalments } = paymentPlan;
  const sortedInstalments = sortBy(instalments, (i) => i.dueDate);
  const firstInstalment = sortedInstalments && sortedInstalments[0];
  const lastInstalment = sortedInstalments && sortedInstalments[sortedInstalments.length - 1];
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([CREATE_PAYMENT_PLAN_STORE_KEY]);
  const mostRecentPaidInstalment =
    instalments &&
    sortBy(
      instalments.filter((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.PAID),
      (i) => i.dueDate
    ).pop();
  const paidInstalments = instalments && instalments.filter((i) => i.status === PaymentPlanInstalmentDTOStatusEnum.PAID);
  const totalPaid = paidInstalments && paidInstalments.reduce((acc, instalment) => acc + instalment.amount, 0);
  const items: Array<{ key: string; value: string }> = [
    { key: 'Id', value: paymentPlan.id },
    { key: 'Created Date', value: formatISOStringDate(createdAt) },
    { key: 'Status', value: paymentPlan.status },
    { key: 'Total Paid', value: totalPaid ? penceToPounds(totalPaid) : '' },
    { key: 'First Instalment', value: firstInstalment ? formatDate(firstInstalment.dueDate) : '' },
    { key: 'Last Instalment', value: lastInstalment ? formatDate(lastInstalment.dueDate) : '' },
    { key: 'Most Recent Paid Date', value: mostRecentPaidInstalment ? formatDate(mostRecentPaidInstalment.dueDate) : 'NA' },
    { key: 'Number Of Instalments', value: `${instalments && instalments.length}` },
  ];
  return (
    <RepositCard
      flush
      title="Payment Plan"
      headerContent={
        <ActionButton onClick={() => setIsModalShowing(true)} buttonType={'secondary'}>
          View Schedule
        </ActionButton>
      }
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
        ) : undefined
      }
    >
      <>
        {isModalShowing ? <PaymentPlanModal paymentPlan={paymentPlan} onDismiss={() => setIsModalShowing(false)} /> : null}
        <InlineTable items={items} />
      </>
    </RepositCard>
  );
};
