import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-grid-system';
import { useDebounce } from 'use-debounce';

import { Header2 } from '../../../components/Typography';
import RepositList from '../../../components/Reposit/RepositList';
import Pagination from '../../../components/Pagination';
import Loading from '../../../components/Loading';
import Error from '../../../components/Error';
import { ContentContainer } from '../../../components/Common';
import Search from '../../../components/Search';
import Sort from '../../../components/Sort';
import {
  fetchRepositsRequested,
  updateFilters as updateFiltersActionCreator,
  Page,
  goToPage,
  FETCH_REPOSITS_STORE_KEY,
} from '../../../redux/reposit-list/reposit-list.actions';
import {
  getRepositList,
  getRepositListFilters,
  getRepositListPagination,
} from '../../../redux/reposit-list/reposit-list.selectors';
import { RepositListFilters } from '../../../redux/reposit-list/reposit-list.types';
import {
  OrderStatusFilter,
  RepositStatusFilter,
  TenancyStatusFilter,
  RepositSort,
  LetOnlyFilter,
} from '../../../constants/reposit';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import NoRepositsList from '../../../components/Reposit/NoRepositsList';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import SelectFilter from '../../../components/SelectFilter';

interface RepositListContainerProps {
  organizationId?: string;
}

export interface RepositFilterOption {
  label: string;
  value: string;
  orderStatus: string;
  tenancyStatus: string;
  includeDeleted: boolean;
  onlyDeleted: boolean;
}

const filters: RepositFilterOption[] = [
  {
    label: 'All',
    value: RepositStatusFilter.ALL,
    orderStatus: '',
    tenancyStatus: '',
    includeDeleted: true,
    onlyDeleted: false,
  },
  {
    label: 'Draft',
    value: RepositStatusFilter.DRAFT,
    orderStatus: OrderStatusFilter.DRAFT,
    tenancyStatus: '',
    includeDeleted: false,
    onlyDeleted: false,
  },
  {
    label: 'Pending',
    value: RepositStatusFilter.PENDING,
    orderStatus: OrderStatusFilter.PENDING,
    tenancyStatus: '',
    includeDeleted: false,
    onlyDeleted: false,
  },
  {
    label: 'Active',
    value: RepositStatusFilter.ACTIVE,
    orderStatus: OrderStatusFilter.COMPLETE,
    tenancyStatus: TenancyStatusFilter.ACTIVE,
    includeDeleted: false,
    onlyDeleted: false,
  },
  {
    label: 'Checked Out',
    value: RepositStatusFilter.CHECKED_OUT,
    orderStatus: OrderStatusFilter.COMPLETE,
    tenancyStatus: TenancyStatusFilter.CHECKED_OUT,
    includeDeleted: false,
    onlyDeleted: false,
  },
  {
    label: 'Deleted',
    value: RepositStatusFilter.DELETED,
    orderStatus: '',
    tenancyStatus: '',
    includeDeleted: true,
    onlyDeleted: true,
  },
];

const RepositListContainer: React.FC<RepositListContainerProps> = ({ organizationId }) => {
  const dispatch = useDispatch();

  const repositListLoadingSelector = createLoadingSelector([FETCH_REPOSITS_STORE_KEY]);
  const getRepositListError = createErrorMessageSelector([FETCH_REPOSITS_STORE_KEY]);

  const fetchReposits = useCallback(() => dispatch(fetchRepositsRequested(organizationId)), [dispatch, organizationId]);
  const repositsGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);
  const isRepositListFetching = useSelector(repositListLoadingSelector);
  const error = useSelector(getRepositListError);

  const { sort, query, filter, letOnly } = useSelector(getRepositListFilters);
  const [debouncedQuery] = useDebounce(query, 500);
  const { page, count } = useSelector(getRepositListPagination);
  const updateFilters = useCallback((filters: Partial<RepositListFilters>) => dispatch(updateFiltersActionCreator(filters)), [
    dispatch,
  ]);

  const reposits = useSelector(getRepositList);

  useEffect(() => {
    fetchReposits();
  }, [fetchReposits, debouncedQuery, sort, filter, page, letOnly]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      updateFilters({ query, includeDeleted: true, onlyDeleted: false });
    },
    [query, updateFilters]
  );

  const setFilter = (filteredValue: string) => {
    const filter = filters.find((f) => f.value === filteredValue);
    if (filter) {
      updateFilters({
        filter: filteredValue,
        orderStatus: filter.orderStatus,
        tenancyStatus: filter.tenancyStatus,
        includeDeleted: filter.includeDeleted,
        onlyDeleted: filter.onlyDeleted,
      });
    }
  };

  const renderReposits = () => {
    if (error) return <Error error={error} />;
    if (isRepositListFetching) return <Loading />;
    if (!isRepositListFetching && !reposits.length) return <NoRepositsList currentFilter={filter} />;

    return (
      <>
        <RepositList reposits={reposits} />
        <Pagination count={count} currentPage={page} onNavigate={repositsGotoPage} />
      </>
    );
  };

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Header2>Reposits</Header2>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <ContentContainer>
            <form onSubmit={handleSubmit}>
              <Search onChange={(query) => updateFilters({ query })} value={query} placeholder="Search Reposits" />
            </form>
          </ContentContainer>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <SelectFilter label="Filter by status" onSelect={(filter) => setFilter(filter)} options={filters} selected={filter} />
        </Col>
        <Col lg={4}>
          <SelectFilter
            label="Let Only"
            onSelect={(letOnly: LetOnlyFilter) => updateFilters({ letOnly })}
            options={[
              { value: LetOnlyFilter.ALL, label: 'All' },
              { value: LetOnlyFilter.YES, label: 'Yes' },
              { value: LetOnlyFilter.NO, label: 'No' },
            ]}
            selected={letOnly}
          />
        </Col>
        <Col lg={4} style={{ paddingBottom: 12 }}>
          <Sort
            label="Sort by"
            onSelect={(sort: RepositSort) => updateFilters({ sort })}
            options={[
              { label: 'End Date - Descending', value: RepositSort.END_DATE_DESCENDING },
              { label: 'End Date - Ascending', value: RepositSort.END_DATE_ASCENDING },
              { label: 'Start Date - Descending', value: RepositSort.START_DATE_DESCENDING },
              { label: 'Start Date - Ascending', value: RepositSort.START_DATE_ASCENDING },
              { label: 'Created At - Descending', value: RepositSort.CREATED_AT_DESCENDING },
              { label: 'Created At - Ascending', value: RepositSort.CREATED_AT_ASCENDING },
            ]}
            selected={sort}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>{renderReposits()}</Col>
      </Row>
    </Container>
  );
};

export default RepositListContainer;
