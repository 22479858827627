import {
  AddressDTO,
  TenancyExpiryDTO,
  LandlordDTO,
  PolicyDTO,
  LandlordDTOTypeEnum,
  ClaimWithCheckoutRelationsDTO,
  ClaimDTOStatusEnum,
} from '@reposit/api-client';
import { get, sortBy, startCase } from 'lodash';
import moment from 'moment';
import countries from 'i18n-iso-countries';

import { IntegratorEntity, ClaimEntity } from '../redux/entities/entities.types';

type excludedKeys = 'id' | 'organizationId' | 'createdAt' | 'updatedAt';

interface CoreAddress extends Omit<AddressDTO, excludedKeys> {}

export const UK_POSTCODE_REG_EX =
  /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const countryList = countries.getNames('en');

export const getCountry = (value: string) => countries.toAlpha3(value);

export const getAddressFirstLine = (address: CoreAddress): string => {
  const { roomNumber, flatNumber, buildingNumber, buildingName, street } = address;
  const streetConcat = buildingNumber ? `${buildingNumber} ${street}` : street;
  const firstLineItems = {
    room: roomNumber ? `${roomNumber}` : null,
    flat: flatNumber ? `${flatNumber}` : null,
    buildingName,
    street: streetConcat,
  };

  const result = Object.values(firstLineItems);
  return result.filter(Boolean).join(', ');
};

export const getAddressTownPostcode = (address: CoreAddress): string => {
  const result = [address.town, address.postcode];
  return result.filter(Boolean).join(', ');
};

export const getDisplayAddress = (address: CoreAddress) => {
  return `${getAddressFirstLine(address)}, ${getAddressTownPostcode(address)}`;
};

export const isANotFoundError = (error: string) => {
  return error.includes('not found');
};

export const getErrorMessage = (error: any) => {
  const err = get(error, 'response.data.message', error);
  return Array.isArray(err) ? 'Something went wrong.' : err;
};

export const getExpiryDate = (tenancyExpiryDates: TenancyExpiryDTO[]): TenancyExpiryDTO => {
  const sortedDates = sortBy(tenancyExpiryDates, (o: TenancyExpiryDTO) => {
    return moment(o.endDate);
  }).reverse();

  return sortedDates[0];
};

export const getLandlordFullName = (landlord: LandlordDTO) => {
  if (!landlord) return null;
  if (landlord.type === LandlordDTOTypeEnum.COMPANY) {
    return landlord.companyName;
  }
  return `${landlord.firstName} ${landlord.lastName}`;
};

export const getLatestPolicyDocument = (policy: PolicyDTO) => {
  if (!policy) return null;
  if (!policy.documents || !policy.documents.length) return null;
  const documents = sortBy(policy.documents, (document) => moment(document.createdAt)).reverse();
  return documents[0].document;
};

export const resolveClaimStatus = (status: string) => {
  return startCase(status);
};

export const getClaimStatus = (
  claim: ClaimEntity | ClaimWithCheckoutRelationsDTO,
  isAgentDeciding: boolean,
  isTenantDeciding: boolean
) => {
  if (claim.supplierPaidAt && claim.status !== ClaimDTOStatusEnum.RESOLVED) {
    return 'AGENT PAID';
  } else if (claim.status === ClaimDTOStatusEnum.MEDIATION) {
    if (isAgentDeciding) {
      return `AWAITING SUPPLIER RESPONSE`;
    } else if (isTenantDeciding) {
      return 'AWAITING TENANT RESPONSE';
    } else {
      return 'AWAITING AGENT FINAL PROPOSAL';
    }
  }
  return resolveClaimStatus(claim.status);
};

export const truncateFilename = (inputString: string, truncateThreshold: number = 20) => {
  if (inputString.length > truncateThreshold) {
    const hasExtension = inputString.lastIndexOf('.') > 0;
    let filename = hasExtension ? inputString.substr(0, inputString.lastIndexOf('.')) : inputString;
    let extension = hasExtension ? inputString.substr(inputString.lastIndexOf('.') + 1, inputString.length) : null;

    const prependLength = 3;
    const partOne = filename.substring(0, truncateThreshold - prependLength);
    const partTwo = filename.substring(filename.length - prependLength, filename.length);
    filename = `${partOne}...${partTwo}`;
    return extension ? filename + '.' + extension : filename;
  }
  return inputString;
};

export const formatIntegratorOptions = (integrators: IntegratorEntity[]) => {
  return integrators.map((i) => ({ label: i.name, value: i.id }));
};

export const phoneNumberStringMatcher = /^\+?[0-9 ]+$/;

export const penceToPounds = (value: number, decimalPlaces: number = 2): string => {
  return (value / 100).toFixed(decimalPlaces);
};
