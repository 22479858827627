import { FetchOrganizationUsersByUserIdPayload } from './user.types';

export const FETCH_ORGANIZATION_USERS_STORE_KEY = 'USER/FETCH_ORGANIZATION_USERS';
export const FETCH_ORGANIZATION_USERS_API_REQUESTED = `USER/FETCH_ORGANIZATION_USERS_API_REQUESTED`;
export const FETCH_ORGANIZATION_USERS_API_SUCCESS = 'USER/FETCH_ORGANIZATION_USERS_API_SUCCESS';
export const FETCH_ORGANIZATION_USERS_API_FAILED = 'USER/FETCH_ORGANIZATION_USERS_API_FAILED';

export const FETCH_USER_BY_ID_STORE_KEY = 'USER/FETCH_USER_BY_ID';
export const FETCH_USER_BY_ID_API_REQUESTED = 'USER/FETCH_USER_BY_ID_API_REQUESTED';
export const FETCH_USER_BY_ID_API_SUCCESS = 'USER/FETCH_USER_BY_ID_API_SUCCESS';
export const FETCH_USER_BY_ID_API_FAILED = 'USER/FETCH_USER_BY_ID_API_FAILED';

export const RESET_USER_PASSWORD_STORE_KEY = 'USER/RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_API_REQUESTED = 'USER/RESET_USER_PASSWORD_API_REQUESTED';
export const RESET_USER_PASSWORD_API_SUCCESS = 'USER/RESET_USER_PASSWORD_API_SUCCESS';
export const RESET_USER_PASSWORD_API_FAILED = 'USER/RESET_USER_PASSWORD_API_FAILED';

export const FETCH_ORGANIZATION_USER_BY_USER_ID_STORE_KEY = 'USER/FETCH_ORGANIZATION_USER_BY_USER_ID';
export const FETCH_ORGANIZATION_USER_BY_USER_ID_API_REQUESTED = 'USER/FETCH_ORGANIZATION_USER_BY_USER_ID_API_REQUESTED';
export const FETCH_ORGANIZATION_USER_BY_USER_ID_API_SUCCESS = 'USER/FETCH_ORGANIZATION_USER_BY_USER_ID_API_SUCCESS';
export const FETCH_ORGANIZATION_USER_BY_USER_ID_API_FAILED = 'USER/FETCH_ORGANIZATION_USER_BY_USER_ID_API_FAILED';

export function fetchOrganizationUsersRequested(organizationId?: string) {
  return {
    type: FETCH_ORGANIZATION_USERS_API_REQUESTED,
    payload: {
      organizationId,
    },
  } as const;
}

export function fetchOrganizationUsersSuccess(payload: string[]) {
  return {
    type: FETCH_ORGANIZATION_USERS_API_SUCCESS,
    payload,
  } as const;
}

export function fetchOrganizationUsersFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_USERS_API_FAILED,
    payload: error,
  } as const;
}

export function fetchUserByIdRequested(userId: string) {
  return {
    type: FETCH_USER_BY_ID_API_REQUESTED,
    payload: { userId },
  } as const;
}

export function fetchUserByIdSuccess() {
  return {
    type: FETCH_USER_BY_ID_API_SUCCESS,
  } as const;
}

export function fetchUserByIdFailed(error: string) {
  return {
    type: FETCH_USER_BY_ID_API_FAILED,
    payload: error,
  } as const;
}

export function resetUserPasswordRequested(userId: string, email: string) {
  return {
    type: RESET_USER_PASSWORD_API_REQUESTED,
    payload: { userId, email },
  } as const;
}

export function resetUserPasswordSuccess() {
  return {
    type: RESET_USER_PASSWORD_API_SUCCESS,
  } as const;
}

export function resetUserPasswordFailed(error: string) {
  return {
    type: FETCH_USER_BY_ID_API_FAILED,
    payload: error,
  } as const;
}

export function fetchOrganizationUsersByUserIdRequested(payload: FetchOrganizationUsersByUserIdPayload) {
  return {
    type: FETCH_ORGANIZATION_USER_BY_USER_ID_API_REQUESTED,
    payload,
  } as const;
}

export function fetchOrganizationUsersByUserIdSuccess() {
  return {
    type: FETCH_ORGANIZATION_USER_BY_USER_ID_API_SUCCESS,
  } as const;
}

export function fetchOrganizationUsersByUserIdFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_USER_BY_USER_ID_API_FAILED,
    payload: error,
  } as const;
}

export type UserActionTypes = ReturnType<
  | typeof fetchOrganizationUsersRequested
  | typeof fetchOrganizationUsersSuccess
  | typeof fetchOrganizationUsersFailed
  | typeof fetchUserByIdRequested
  | typeof fetchUserByIdSuccess
  | typeof fetchUserByIdFailed
  | typeof resetUserPasswordRequested
  | typeof resetUserPasswordSuccess
  | typeof resetUserPasswordFailed
  | typeof fetchOrganizationUsersByUserIdRequested
  | typeof fetchOrganizationUsersByUserIdSuccess
  | typeof fetchOrganizationUsersByUserIdFailed
>;
