import { TenantDTO, OrderCustomerDTO } from '@reposit/api-client';
import React from 'react';
import Modal from '../Modal';
import { Header3, P1 } from '../Typography';
import PaymentPlanForm from './PaymentPlanForm';
import { penceToPounds } from '../../utils/common.utils';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../containers/FlashMessage';
import { CREATE_PAYMENT_PLAN_STORE_KEY } from '../../redux/payment-plan/payment-plan.actions';
import FlashMessage from '../FlashMessage';

interface PaymentPlanModalProps {
  onDismiss: () => void;
  totalBalance?: number;
  orderCustomers: OrderCustomerDTO[];
  tenants: TenantDTO[];
  onSubmit: (values: PaymentPlanFormValues) => Promise<any>;
  isSubmitting: boolean;
}

export interface PaymentPlanFormValues {
  orderCustomerId: string;
  startDate?: Date;
  amount: number;
  instalments: number;
}

const PaymentPlanModal: React.FC<PaymentPlanModalProps> = ({
  onDismiss,
  totalBalance,
  orderCustomers,
  tenants,
  onSubmit,
  isSubmitting,
}) => {
  const [flashMessagePayload, dismissFlashMessage] = useFlashMessage([CREATE_PAYMENT_PLAN_STORE_KEY]);
  return (
    <Modal onDismiss={onDismiss}>
      {flashMessagePayload ? (
        <FlashMessage payload={flashMessagePayload} onDismiss={dismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} />
      ) : null}
      <Header3 style={{ marginBottom: 15 }}>Open payment plan</Header3>
      {totalBalance ? (
        <>
          <P1>The remaining balance is {<span style={{ fontWeight: 'bold' }}>£{penceToPounds(totalBalance)}</span>}.</P1>

          <PaymentPlanForm
            tenants={tenants}
            orderCustomers={orderCustomers}
            totalBalance={totalBalance}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </>
      ) : (
        <P1>There is currently no payment in the system for a payment plan to be set up against! Please try again later.</P1>
      )}
    </Modal>
  );
};

export default PaymentPlanModal;
