import {
  ClaimItemDTOTypeEnum,
  ClaimItemProposalDTOSettledSourceEnum,
  ClaimProposalDTOSourceEnum,
  ClaimProposalDTOStatusEnum,
  ClaimProposalDTOTypeEnum,
} from '@reposit/api-client';
import moment from 'moment';
import { ClaimItemEntity, ClaimItemProposalEntity, ClaimProposalEntity, DocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';
import { getClaimItemById } from './claim-item.selectors';
import { getClaimById } from './claim.selectors';

export interface ClaimItem {
  id: string;
  description: string;
  type: ClaimItemDTOTypeEnum;
  claimId: string;
  itemProposals: ItemProposal[];
  isSettled: boolean;
  deletedAt?: string;
}

export interface ItemProposal {
  id: string;
  claimProposal: ClaimProposal;
  settled?: boolean;
  settledSource?: ClaimItemProposalDTOSettledSourceEnum;
  amount: number;
  explanation?: string;
  documents?: DocumentEntity[];
  createdAt: string;
  updatedAt: string;
}

export interface ClaimProposal {
  id: string;
  type: ClaimProposalDTOTypeEnum;
  round: number;
  source: ClaimProposalDTOSourceEnum;
  status: ClaimProposalDTOStatusEnum;
}

export const getProposalsByClaimId = (state: AppState, claimId: string): ClaimProposalEntity[] => {
  const claim = getClaimById(state, claimId);
  if (!claim || !claim.proposals) return [];
  return claim.proposals.map((p) => state.entities.claimProposal[p]);
};

const getProposalById = (state: AppState, proposalId: string): ClaimProposalEntity | undefined => {
  return state.entities.claimProposal[proposalId];
};

export interface ClaimItemProposal {
  proposal: ClaimItemProposalEntity;
  claimItem: ClaimItemEntity;
  documents: DocumentEntity[];
}

export interface AgentDocumentsAndProposals {
  firstDocuments: DocumentEntity[];
  firstProposal?: ClaimProposalEntity;
  secondDocuments: DocumentEntity[];
  secondProposal?: ClaimProposalEntity;
}

export interface TenantDocumentsAndProposal {
  documents: DocumentEntity[];
  proposal: ClaimProposalEntity;
}

export const getProposalItemsByProposalId = (state: AppState, proposalId: string): ClaimItemProposal[] => {
  const proposal = getProposalById(state, proposalId);
  if (!proposal) return [];
  return proposal.itemProposals.map((ip) => {
    const proposal = state.entities.claimItemProposal[ip];
    const claimItem = state.entities.claimItem[proposal.claimItemId];
    const documents = getDocumentsByItemProposalId(state, ip);
    return {
      proposal,
      claimItem,
      documents,
    };
  });
};

export const getDocumentsByItemProposalId = (state: AppState, id: string): DocumentEntity[] => {
  const itemProposal = state.entities.claimItemProposal[id];
  if (!itemProposal || !itemProposal.documents) return [];

  const claimItemDocs = itemProposal.documents.map((d) => state.entities.claimItemDocument[d]);

  return claimItemDocs.map((d) => state.entities.document[d.document]);
};

export const getFirstAgentProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return proposals && proposals.find((p) => p.round === 1);
};

export const getFirstTenantProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return proposals && proposals.find((p) => p.round === 2);
};

export const getSecondAgentProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  return proposals && proposals.find((p) => p.round === 3);
};

export const getFirstAgentProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const prop = proposals && proposals.find((p) => p.round === 1);
  if (!prop) return undefined;
  const items = getProposalItemsByProposalId(state, prop.id);

  return items.reduce((acc, item) => acc + item.proposal.amount, 0);
};

export const getFirstTenantProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const prop = proposals && proposals.find((p) => p.round === 2);
  if (!prop) return undefined;
  const items = getProposalItemsByProposalId(state, prop.id);
  return items.reduce((acc, item) => acc + item.proposal.amount, 0);
};

export const getSecondAgentProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const proposals = getProposalsByClaimId(state, claimId);
  const prop = proposals && proposals.find((p) => p.round === 3);
  if (!prop) return undefined;
  const items = getProposalItemsByProposalId(state, prop.id);

  return items.reduce((acc, item) => acc + item.proposal.amount, 0);
};

export const getProposalTotalAmountsAreEqual = (state: AppState, claimId: string): boolean => {
  const secondAgentProposalTotalAmount = getSecondAgentProposalTotalAmount(state, claimId);
  const firstTenantProposalTotalAmount = getFirstTenantProposalTotalAmount(state, claimId);
  if (!secondAgentProposalTotalAmount || !firstTenantProposalTotalAmount) return false;
  return secondAgentProposalTotalAmount === firstTenantProposalTotalAmount;
};

export const getLatestProposalTotalAmount = (state: AppState, claimId: string): number | undefined => {
  const isAgentDeciding = getIsAgentDeciding(state, claimId);

  const tenantFirstProposal = getFirstTenantProposal(state, claimId);
  const latestAgentProposal = getLatestAgentProposal(state, claimId);

  if (!latestAgentProposal) return undefined;

  const claimItems = getMediationClaimItemsByClaimId(state, claimId);
  if (!claimItems) return undefined;

  const latestAgentItems = getProposalItemsByProposalId(state, latestAgentProposal.id);
  const latestTenantItems =
    tenantFirstProposal && tenantFirstProposal ? getProposalItemsByProposalId(state, tenantFirstProposal.id) : [];
  return claimItems.reduce((acc, claimItem) => {
    const latestAgentItem = latestAgentItems.find((i) => i.claimItem.id === claimItem.id) as ClaimItemProposal;
    const latestTenantItem = latestTenantItems.find((i) => i.claimItem.id === claimItem.id) as ClaimItemProposal;
    const settledItem = claimItem.itemProposals.find((ip) => ip.settled);
    const item = isAgentDeciding ? latestTenantItem : latestAgentItem || latestTenantItem;
    const value = settledItem ? settledItem.amount : item.proposal.amount;
    return acc + value;
  }, 0);
};

export const getAgentLatestPropsoalItemByClaimItemId = (
  state: AppState,
  claimId: string,
  claimItemId: string
): ClaimItemProposal | undefined => {
  const firstProposal = getFirstAgentProposal(state, claimId);
  const secondProposal = getSecondAgentProposal(state, claimId);
  const proposal = secondProposal || firstProposal;
  if (!proposal) return undefined;

  const items = getProposalItemsByProposalId(state, proposal.id);
  return items.find((i) => i.claimItem.id === claimItemId);
};

export const getAllAgentDocumentsAndProposalsForClaimItem = (
  state: AppState,
  claimItemId: string
): AgentDocumentsAndProposals | undefined => {
  const claimItem = getClaimItemById(state, claimItemId);
  if (!claimItem) return undefined;

  const firstProposal = getFirstAgentProposal(state, claimItem.claimId);
  const secondProposal = getSecondAgentProposal(state, claimItem.claimId);

  if (!firstProposal && !secondProposal) return undefined;

  const firstProposalItems = firstProposal ? getProposalItemsByProposalId(state, firstProposal.id) : [];
  const secondProposalItems = secondProposal ? getProposalItemsByProposalId(state, secondProposal.id) : [];

  const firstClaimItemProposal = firstProposalItems.find((i) => i.claimItem.id === claimItemId);
  const firstDocs = firstClaimItemProposal ? getDocumentsByItemProposalId(state, firstClaimItemProposal.proposal.id) : [];
  const secondClaimItemProposal = secondProposalItems.find((i) => i.claimItem.id === claimItemId);
  const secondDocs = secondClaimItemProposal ? getDocumentsByItemProposalId(state, secondClaimItemProposal.proposal.id) : [];

  return { firstDocuments: firstDocs, firstProposal, secondDocuments: secondDocs, secondProposal };
};

export const getAllTenantDocumentsAndProposalsForClaimItem = (
  state: AppState,
  claimItemId: string
): TenantDocumentsAndProposal | undefined => {
  const claimItem = getClaimItemById(state, claimItemId);
  if (!claimItem) return undefined;

  const tenantProposal = getFirstTenantProposal(state, claimItem.claimId);
  if (!tenantProposal) return undefined;

  const tenantProposalItems = getProposalItemsByProposalId(state, tenantProposal.id);
  const claimItemProposal = tenantProposalItems.find((i) => i.claimItem.id === claimItemId);

  const docs = claimItemProposal ? getDocumentsByItemProposalId(state, claimItemProposal.proposal.id) : [];
  return { documents: docs, proposal: tenantProposal };
};

export const getFirstTenantProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposal = getFirstTenantProposal(state, claimId);
  if (!proposal) return [];
  return getProposalItemsByProposalId(state, proposal.id);
};

export const getFirstAgentProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposal = getFirstAgentProposal(state, claimId);
  if (!proposal) return [];
  return getProposalItemsByProposalId(state, proposal.id);
};

export const getSecondAgentProposalItems = (state: AppState, claimId: string): ClaimItemProposal[] => {
  const proposal = getSecondAgentProposal(state, claimId);
  if (!proposal) return [];
  return getProposalItemsByProposalId(state, proposal.id);
};

export const getIsAgentDeciding = (state: AppState, claimId: string): boolean => {
  const proposal = getFirstTenantProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE);
};

export const getIsAgentNegotiating = (state: AppState, claimId: string): boolean => {
  const proposal = getSecondAgentProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.DRAFT);
};

export const getLatestAgentProposal = (state: AppState, claimId: string): ClaimProposalEntity | undefined => {
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);
  return secondAgentProposal || firstAgentProposal;
};

export const getIsTenantDeciding = (state: AppState, claimId: string): boolean => {
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);
  const firstTenantProposal = getFirstTenantProposal(state, claimId);
  const firstDeciding =
    !!(firstAgentProposal && firstAgentProposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE) ||
    !!(
      firstTenantProposal &&
      (firstTenantProposal.status === ClaimProposalDTOStatusEnum.DRAFT ||
        firstTenantProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT)
    );
  const secondDeciding = !!(secondAgentProposal && secondAgentProposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE);
  return firstDeciding || secondDeciding;
};

export const getMediationClaimItemsByClaimId = (state: AppState, claimId: string): ClaimItem[] => {
  const claim = getClaimById(state, claimId);
  const firstAgentProposal = getFirstAgentProposal(state, claimId);
  const firstTenantProposal = getFirstTenantProposal(state, claimId);
  const secondAgentProposal = getSecondAgentProposal(state, claimId);
  const isAgentDeciding = getIsAgentDeciding(state, claimId);
  const isAgentNegotiating = getIsAgentNegotiating(state, claimId);

  const firstTenantProposalItems = firstTenantProposal ? getProposalItemsByProposalId(state, firstTenantProposal.id) : [];
  const secondAgentProposalItems = secondAgentProposal ? getProposalItemsByProposalId(state, secondAgentProposal.id) : [];

  const proposalsInRoundOrder = [firstAgentProposal, firstTenantProposal, secondAgentProposal].filter(
    (i) => i
  ) as ClaimProposal[];

  if (!claim || !claim.items) return [];
  return claim.items.map((cid) => {
    const entity = state.entities.claimItem[cid];
    // if tenant has agreed - it is settled
    const foundTenantItem = firstTenantProposalItems.find((i) => i.claimItem.id === cid);

    const foundAgentItem = secondAgentProposalItems.find((i) => i.claimItem.id === cid);

    // is the whole thing settled
    const hasTenantSettledFirstRound = !!(firstAgentProposal && firstAgentProposal.status === ClaimProposalDTOStatusEnum.SETTLED);

    const isTenantProposalFinal = !!(
      firstTenantProposal &&
      (firstTenantProposal.status === ClaimProposalDTOStatusEnum.SETTLED ||
        firstTenantProposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE ||
        firstTenantProposal.status === ClaimProposalDTOStatusEnum.COUNTERED)
    );
    const settledInTenantRound = !!(
      firstTenantProposal &&
      foundTenantItem &&
      foundTenantItem.proposal.settled &&
      isTenantProposalFinal
    );

    const permittedStatuses: ClaimProposalDTOStatusEnum[] = [
      ClaimProposalDTOStatusEnum.DISPUTED,
      ClaimProposalDTOStatusEnum.SETTLED,
    ];

    const settledInFinalRound = !!(
      secondAgentProposal &&
      permittedStatuses.includes(secondAgentProposal.status) &&
      !isAgentDeciding &&
      !isAgentNegotiating &&
      foundAgentItem &&
      foundAgentItem.proposal.settled
    );
    const hasTenantSettled = hasTenantSettledFirstRound || settledInTenantRound;

    const isSettled = hasTenantSettled || settledInFinalRound;
    const itemProposals: ItemProposal[] = proposalsInRoundOrder
      .map((proposal) => {
        const items = getProposalItemsByProposalId(state, proposal.id);
        const itemProposal = items.find((i) => i.claimItem.id === cid);
        return itemProposal
          ? ({ ...itemProposal.proposal, claimProposal: proposal, documents: itemProposal.documents } as ItemProposal)
          : undefined;
      })
      .filter((i) => i) as ItemProposal[];
    return {
      ...entity,
      isSettled,
      itemProposals,
    };
  });
};

export const getHasAgentRespondedToAllItems = (state: AppState, claimId: string): boolean => {
  const proposal = getSecondAgentProposal(state, claimId);
  if (!proposal) return false;
  const claimItems = getMediationClaimItemsByClaimId(state, claimId);
  const items = getProposalItemsByProposalId(state, proposal.id);
  if (!claimItems || !items) return false;
  return claimItems.length === items.length;
};

export const getHasTenantDisputed = (state: AppState, claimId: string): boolean => {
  const proposal = getSecondAgentProposal(state, claimId);
  return !!(proposal && proposal.status === ClaimProposalDTOStatusEnum.DISPUTED);
};

// get claim item proposal id by claim item id and propsoal id
export const getClaimItemProposalByClaimItemIdAndProposalId = (state: AppState, claimItemId: string, claimProposalId: string) => {
  const proposal = getProposalById(state, claimProposalId);
  if (!proposal) return undefined;

  const itemProposals = getProposalItemsByProposalId(state, proposal.id);
  return itemProposals.find((ip) => ip.claimItem.id === claimItemId);
};

export const getHasAgentUploadedEvidenceForEachClaimItem = (state: AppState, claimId: string): boolean => {
  const claimItems = getMediationClaimItemsByClaimId(state, claimId);
  if (!claimItems || !claimItems.length) return false;
  return claimItems.every((ci) => {
    const docsAndProposals = getAllAgentDocumentsAndProposalsForClaimItem(state, ci.id);
    return docsAndProposals && [...docsAndProposals.firstDocuments, ...docsAndProposals.secondDocuments].length > 0;
  });
};

export const getFirstAgentProposalIdByClaimItem = (state: AppState, claimItemId: string) => {
  const claimItem = getClaimItemById(state, claimItemId);
  if (!claimItem) return undefined;
  const claim = getClaimById(state, claimItem.claimId);
  if (!claim) return undefined;
  const claimProposals = getProposalsByClaimId(state, claim.id);
  const firstAgentProposal = claimProposals.find((cp) => cp.round === 1);
  return firstAgentProposal && firstAgentProposal.id;
};

export const getLatestAgentProposalIdByClaimItemId = (state: AppState, claimItemId: string) => {
  const claimItem = getClaimItemById(state, claimItemId);
  if (!claimItem) return undefined;
  const claim = getClaimById(state, claimItem.claimId);
  if (!claim) return undefined;
  const latestProposal = getLatestAgentProposal(state, claim.id);
  return latestProposal && latestProposal.id;
};

export const getHasAgentAcceptedTenantProposal = (state: AppState, claimId: string): boolean => {
  const tenantProposal = getFirstTenantProposal(state, claimId);
  if (!tenantProposal) return false;
  return tenantProposal.status === ClaimProposalDTOStatusEnum.SETTLED;
};

export const getTenantProposalResponseDeadline = (state: AppState, claimId: string): string | undefined => {
  const tenantProposal = getFirstTenantProposal(state, claimId);
  if (!tenantProposal) return undefined;
  return tenantProposal.responseDeadline;
};

export const getTenantProposalResponseDeadlineDaysRemaining = (state: AppState, claimId: string): number => {
  const responseDeadline = getTenantProposalResponseDeadline(state, claimId);
  return responseDeadline ? moment(responseDeadline).diff(moment().startOf('day'), 'day') : 0;
};

export const getSupplierItemDocumentsByClaimId = (state: AppState, claimId: string): DocumentEntity[] => {
  const firstItems = getFirstAgentProposalItems(state, claimId);
  const firstItemDocuments = firstItems.flatMap((i) => i.documents);
  const secondItems = getSecondAgentProposalItems(state, claimId);
  const secondItemDocuments = secondItems.flatMap((i) => i.documents);
  return [...firstItemDocuments, ...secondItemDocuments];
};

export const getTenantItemDocumentsByClaimId = (state: AppState, claimId: string): DocumentEntity[] => {
  const items = getFirstTenantProposalItems(state, claimId);
  return items.flatMap((i) => i.documents);
};
