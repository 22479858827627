import { OrderCustomerDTO, TenantDTO } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import * as yup from 'yup';
import { PaymentPlanFormValues } from '.';
import poundIcon from '../../assets/svg/pound-sterling.svg';
import { DATE_FORMAT } from '../../constants/dates';
import { TenantEntity } from '../../redux/entities/entities.types';
import { twoDPRegex } from '../../utils/regex/number.regex';
import Button from '../Button';
import { Input } from '../FormFields';
import DatePicker from '../FormFields/DatePicker';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import Select from '../FormFields/Select';

const EXTRA_INSTALMENT = 1;

const ActionBar = styled.div`
  display: flex;
  padding: 24px 0;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;

  button {
    margin-right: 10px;
  }
`;

interface PaymentPlanFormProps {
  orderCustomers: OrderCustomerDTO[];
  tenants: TenantDTO[];
  totalBalance: number;
  onSubmit: (values: PaymentPlanFormValues) => Promise<any>;
  isSubmitting: boolean;
}

const Schema = yup.object().shape({
  orderCustomerId: yup.string().required('You must choose a tenant to set up a payment plan for'),
  startDate: yup
    .date()
    .typeError('Start date must be a valid date')
    .required('Required')
    .test('startDate', 'The start date must be three days in the future', function (value: Date) {
      const minDate = moment().startOf('day').add(3, 'days');
      return moment(value).isSameOrAfter(minDate);
    }),
  amount: yup
    .number()
    .required('You must choose an amount')
    .min(0, 'Amount must be above 0')
    .test('two-decimals', 'Item amount must have a maximum of two decimal places', (value) => {
      return twoDPRegex.test(value);
    }),
  instalments: yup.number().min(0, 'Must have at least one instalment'),
});

const PaymentPlanForm: React.FC<PaymentPlanFormProps> = ({ tenants, orderCustomers, totalBalance, onSubmit, isSubmitting }) => {
  const initialValues: PaymentPlanFormValues = {
    orderCustomerId: orderCustomers.length ? orderCustomers[0].id : '',
    startDate: moment().startOf('day').add(3, 'days').toDate(),
    amount: 0,
    instalments: 0,
  };

  const renderTenantOptions = (tenants: TenantEntity[]): JSX.Element[] => {
    return tenants.map((t) => {
      const oc = orderCustomers.find((oc) => oc.customer.userId === t.userId);
      return (
        <option key={t.id} value={oc ? oc.id : ''}>
          {t.firstName} {t.lastName}
        </option>
      );
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={onSubmit}>
      {({ values, handleSubmit, handleBlur, touched, errors, setFieldValue }: FormikProps<PaymentPlanFormValues>) => (
        <form onSubmit={handleSubmit}>
          <Container fluid>
            <Row>
              <Col lg={12} style={{ padding: 0 }}>
                <FieldWithLabel label="Tenant">
                  <Select
                    onChange={(orderCustomerId: string) => {
                      setFieldValue('orderCustomerId', orderCustomerId);
                    }}
                    value={values.orderCustomerId}
                  >
                    {renderTenantOptions(tenants)}
                  </Select>
                </FieldWithLabel>
              </Col>
            </Row>
            <Row>
              <Col lg={12} style={{ padding: 0 }}>
                <FieldWithLabel label="Start Date">
                  <DatePicker
                    name="startDate"
                    value={values.startDate}
                    dateFormat={DATE_FORMAT}
                    onBlur={handleBlur}
                    touched={touched.startDate}
                    placeholder={'DD/MM/YYYY'}
                    error={errors.startDate}
                    minDate={moment().startOf('day').add(3, 'days').toDate()}
                    onChange={(date) => {
                      setFieldValue('startDate', date);
                    }}
                  />
                </FieldWithLabel>
              </Col>
            </Row>
            <Row>
              <Col lg={12} style={{ padding: 0 }}>
                <FieldWithLabel label="Amount per instalment">
                  <Input
                    name="amount"
                    type="number"
                    icon={poundIcon}
                    error={errors.amount}
                    touched={touched.amount}
                    onChange={(e) => {
                      const calculatedInstalments = calculateInstalmentsFromAmount(e.target.valueAsNumber * 100, totalBalance);
                      setFieldValue('instalments', calculatedInstalments);
                      setFieldValue('amount', e.target.valueAsNumber);
                    }}
                    value={values.amount ? values.amount : ''}
                    onBlur={handleBlur}
                  />
                </FieldWithLabel>
              </Col>
            </Row>
            <Row>
              <Col lg={12} style={{ padding: 0 }}>
                <FieldWithLabel label="Number of instalments">
                  <Input
                    name="instalments"
                    type="number"
                    error={errors.instalments}
                    touched={touched.instalments}
                    onChange={(e) => {
                      const calculatedAmount = calculateAmountFromInstalments(e.target.valueAsNumber * 100, totalBalance);
                      setFieldValue('amount', calculatedAmount);
                      setFieldValue('instalments', e.target.valueAsNumber);
                      console.log({ values });
                    }}
                    value={`${values.instalments}`}
                    onBlur={handleBlur}
                  />
                </FieldWithLabel>
              </Col>
            </Row>
            <ActionBar style={{ marginTop: 20 }}>
              <Button disabled={isSubmitting} type="submit">
                Confirm
              </Button>
            </ActionBar>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default PaymentPlanForm;

const calculateInstalmentsFromAmount = (amount: number, totalBalance: number) => {
  const remainder = totalBalance % amount;
  const quotient = Math.floor(totalBalance / amount);

  return remainder ? quotient + EXTRA_INSTALMENT : quotient;
};

const calculateAmountFromInstalments = (instalments: number, totalBalance: number) => {
  const monthlyAmount = totalBalance / instalments;
  return Number(monthlyAmount.toFixed(2));
};
