import { UserState } from './user.types';
import { UserActionTypes, FETCH_ORGANIZATION_USERS_API_SUCCESS, FETCH_ORGANIZATION_USERS_API_FAILED } from './user.actions';
import { DELETE_ORGANIZATION_USER_API_SUCCESS, OrgnizationUserActionTypes } from '../organization-user/organization-user.actions';

const initialState: UserState = {
  organizationUsers: [],
  haveCurrentOrganizationUsersBeenFetched: false
};

export default (state = initialState, action: UserActionTypes | OrgnizationUserActionTypes): UserState => {
  switch (action.type) {
    case FETCH_ORGANIZATION_USERS_API_SUCCESS:
      return {
        ...state,
        organizationUsers: action.payload,
        haveCurrentOrganizationUsersBeenFetched: true
      };
    case FETCH_ORGANIZATION_USERS_API_FAILED:
      return {
        ...state,
        haveCurrentOrganizationUsersBeenFetched: true
      };
    case DELETE_ORGANIZATION_USER_API_SUCCESS:
      const userId = action.payload;
      const newOrgUsers = state.organizationUsers.filter(id => id !== userId);
      return {
        ...state,
        organizationUsers: newOrgUsers
      };
    default:
      return state;
  }
};
