import { AppState } from '../root.reducer';
import { UserDTOInternalRolesEnum } from '@reposit/api-client/dist';

export const getCurrentUserId = (state: AppState) => state.account.currentUserId;
export const getCurrentOrganizationId = (state: AppState) => state.account.currentOrganizationId;
export const getCurrentOrganization = (state: AppState) => state.account.currentOrganizationId;
export const getMeHasFetched = (state: AppState) => state.account.hasFetched;
export const getAccountCurrentAddressForm = (state: AppState) => state.account.currentAddressForm;
export const getCurrentOrganizationRoles = (state: AppState) => state.account.currentOrganizationRoles;
export const getInternalRoles = (state: AppState) => state.account.internalRoles;
export const hasInternalRole = (state: AppState, role: UserDTOInternalRolesEnum): boolean =>
  state.account.internalRoles ? state.account.internalRoles.some((r) => r === role) : false;

export const getHasRequestedResetPassword = (state: AppState) => state.account.hasRequestedResetPassword;
export const getHasResetPassword = (state: AppState) => state.account.hasResetPassword;
export const getPersistedEmail = (state: AppState) => state.account.persistedEmail;
