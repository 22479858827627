import { OrganizationsApi, UserDTO, UsersApi, OrganizationUserDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentOrganizationId } from '../account/account.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, createUsersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  FETCH_ORGANIZATION_USERS_API_REQUESTED,
  UserActionTypes,
  fetchOrganizationUsersSuccess,
  fetchOrganizationUsersFailed,
  fetchUserByIdSuccess,
  FETCH_USER_BY_ID_API_REQUESTED,
  fetchUserByIdFailed,
  resetUserPasswordFailed,
  resetUserPasswordSuccess,
  RESET_USER_PASSWORD_API_REQUESTED,
  RESET_USER_PASSWORD_STORE_KEY,
  fetchOrganizationUsersByUserIdSuccess,
  fetchOrganizationUsersByUserIdFailed,
  FETCH_ORGANIZATION_USER_BY_USER_ID_API_REQUESTED,
} from './user.actions';
import { FetchUserByIdPayload, ResetPasswordPayload, FetchOrganizationUsersByUserIdPayload } from './user.types';
import { FetchOrganizationUserPayload } from '../organization-user/organization-user.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';
import { setCurrentModal } from '../modal/modal.actions';

// ****************
// WORKERS
// ****************

export function* fetchUsersForOrganization({ payload }: { type: string; payload: FetchOrganizationUserPayload }) {
  try {
    const currentOrganizationId: string = yield select(getCurrentOrganizationId);
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const orgId = payload.organizationId || currentOrganizationId;

    const apiResponse: AxiosResponse<UserDTO[]> = yield call(
      runSagaWithAuth(() => organizationsApi.fetchUsersByOrganizationId(orgId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.users);
    yield put<UserActionTypes>(fetchOrganizationUsersSuccess(ids));
  } catch (e) {
    yield put<UserActionTypes>(fetchOrganizationUsersFailed(get(e, 'response.data.message', e)));
  }
}

export function* fetchUserById({ payload }: { type: string; payload: FetchUserByIdPayload }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const apiResponse: AxiosResponse<UserDTO[]> = yield call(runSagaWithAuth(() => usersApi.findOneByID(payload.userId)));

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.user);

    yield put<UserActionTypes>(fetchUserByIdSuccess());
  } catch (e) {
    yield put<UserActionTypes>(fetchUserByIdFailed(get(e, 'response.data.message', e)));
  }
}

export function* resetUserPassword({ payload }: { type: string; payload: ResetPasswordPayload }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    yield call(runSagaWithAuth(() => usersApi.resetUserPassword(payload.userId)));

    yield put<UserActionTypes>(resetUserPasswordSuccess());

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: RESET_USER_PASSWORD_STORE_KEY,
        message: `Success! Reset password email sent`,
        state: FlashState.SUCCESS,
      })
    );
    yield put(setCurrentModal(''));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: RESET_USER_PASSWORD_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<UserActionTypes>(resetUserPasswordFailed(get(e, 'response.data.message', e)));
  }
}

export function* fetchOrganizationUsersByUserId({ payload }: { type: string; payload: FetchOrganizationUsersByUserIdPayload }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const apiResponse: AxiosResponse<OrganizationUserDTO[]> = yield call(
      runSagaWithAuth(() => usersApi.getOrganizationUsers(payload.userId))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organizationUsers);
    yield put<UserActionTypes>(fetchOrganizationUsersByUserIdSuccess());
  } catch (e) {
    yield put<UserActionTypes>(fetchOrganizationUsersByUserIdFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchUserSagas() {
  yield takeLatest(FETCH_ORGANIZATION_USERS_API_REQUESTED, fetchUsersForOrganization);
  yield takeLatest(FETCH_USER_BY_ID_API_REQUESTED, fetchUserById);
  yield takeLatest(RESET_USER_PASSWORD_API_REQUESTED, resetUserPassword);
  yield takeLatest(FETCH_ORGANIZATION_USER_BY_USER_ID_API_REQUESTED, fetchOrganizationUsersByUserId);
}
