import {
  CreateOrganizationAttributesDTO,
  OrganizationDTO,
  OrganizationInvitesApi,
  OrganizationsApi,
  SearchOrganizationResultsDTO,
} from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage';
import { getErrorMessage } from '../../utils/common.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { fetchOrganizationInvitesRequested } from '../invite/invite.actions';
import { setCurrentModal } from '../modal/modal.actions';
import SCHEMA from '../schema';
import { fetchOrganizationUsersRequested } from '../user/user.actions';
import { createOrganizationInviteApi, createOrganizationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  createOrganizationFailed,
  createOrganizationInviteFailed,
  createOrganizationInviteSuccess,
  createOrganizationSuccess,
  CREATE_ORGANIZATION_API_REQUESTED,
  CREATE_ORGANIZATION_INVITE_API_REQUESTED,
  CREATE_ORGANIZATION_INVITE_STORE_KEY,
  CREATE_ORGANIZATION_STORE_KEY,
  fetchOrganizationByIdFailed,
  fetchOrganizationByIdSuccess,
  FETCH_ORGANIZATION_BY_ID_API_REQUESTED,
  OrganizationActionTypes,
  updateOrganizationMediationSettingsFailed,
  updateOrganizationMediationSettingsRequested,
  updateOrganizationNotesFailed,
  updateOrganizationNotesRequested,
  updateOrganizationNotesSuccess,
  UPDATE_ORG_MEDIATION_SETTINGS,
  UPDATE_ORG_NOTES_STORE_KEY,
} from './organization.actions';
import {
  CreateOrganizationInvitePayload,
  CreateOrganizationPayload,
  FetchOrganizationByIdRequestedPayload,
  UpdateOrganizationMediationSettingsPayload,
  UpdateOrganizationNotesPayload,
} from './organization.types';

// ****************
// WORKERS
// ****************

export function* fetchOrganizationById({ payload }: { type: string; payload: FetchOrganizationByIdRequestedPayload }) {
  try {
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const apiResponse: AxiosResponse<SearchOrganizationResultsDTO> = yield call(
      runSagaWithAuth(() => organizationsApi.findOrganizationById(payload.organizationId))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organization);
    yield put<OrganizationActionTypes>(fetchOrganizationByIdSuccess());
  } catch (e) {
    yield put<OrganizationActionTypes>(fetchOrganizationByIdFailed(get(e, 'response.data.message', e)));
  }
}

export function* createOrganization({ payload }: { type: string; payload: CreateOrganizationPayload }) {
  try {
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const organizationInviteApi: OrganizationInvitesApi = yield createOrganizationInviteApi();
    let attributes: CreateOrganizationAttributesDTO = {};
    attributes = payload.hubspotId ? { hubspotId: payload.hubspotId } : {};

    const createOrgApiResponse: AxiosResponse<OrganizationDTO> = yield call(
      runSagaWithAuth(() =>
        organizationsApi.createOrganization({
          name: payload.organizationName,
          attributes,
          settings: {},
          type: payload.type,
          integratorId: payload.integratorId,
        })
      )
    );

    const org = createOrgApiResponse.data;
    yield syncEntitiesAndGetResults(org, SCHEMA.organization);
    yield call(
      runSagaWithAuth(() =>
        organizationInviteApi.createOrganizationInvite(org.id, {
          email: payload.email,
          integratorId: payload.integratorId,
        })
      )
    );
    yield put<OrganizationActionTypes>(createOrganizationSuccess());
    yield put(push(`/organisations`));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ORGANIZATION_STORE_KEY,
        message: 'Success! Organization Created!',
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<OrganizationActionTypes>(createOrganizationFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ORGANIZATION_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* createOrganizationInvite({ payload }: { type: string; payload: CreateOrganizationInvitePayload }) {
  try {
    const organizationInviteApi: OrganizationInvitesApi = yield createOrganizationInviteApi();

    yield call(
      runSagaWithAuth(() =>
        organizationInviteApi.createOrganizationInvite(payload.organizationId, {
          email: payload.email,
          integratorId: payload.integratorId,
        })
      )
    );
    yield put<OrganizationActionTypes>(createOrganizationInviteSuccess());
    yield put(fetchOrganizationInvitesRequested(payload.organizationId));
    yield put(fetchOrganizationUsersRequested(payload.organizationId));
    yield put(setCurrentModal(''));
    const flashMessage = payload.integratorId
      ? 'Success! Organization User Onboarded For Integrator!'
      : 'Success! Organization Invite Sent!';
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ORGANIZATION_INVITE_STORE_KEY,
        message: flashMessage,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<OrganizationActionTypes>(createOrganizationInviteFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ORGANIZATION_INVITE_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
      })
    );
  }
}

export function* updateOrganizationNotes({ payload }: { payload: UpdateOrganizationNotesPayload; type: string }) {
  try {
    const { organizationId, notes } = payload;
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const response: AxiosResponse<OrganizationDTO> = yield call(
      runSagaWithAuth(() => organizationsApi.updateOrganizationNotes(organizationId, { notes }))
    );

    yield syncEntitiesAndGetResults(response.data, SCHEMA.organization);

    yield put<OrganizationActionTypes>(updateOrganizationNotesSuccess());
    const flashMessage = 'Success! Organization notes updated!';
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_ORG_NOTES_STORE_KEY,
        message: flashMessage,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_ORG_NOTES_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put(updateOrganizationNotesFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateOrganizationMediationSettings({
  payload,
}: {
  payload: UpdateOrganizationMediationSettingsPayload;
  type: string;
}) {
  try {
    const { organizationId, mediationEnabled } = payload;
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const response: AxiosResponse<OrganizationDTO> = yield call(
      runSagaWithAuth(() => organizationsApi.updateOrganizationMediationSettings(organizationId, { mediationEnabled }))
    );
    yield syncEntitiesAndGetResults(response.data, SCHEMA.organization);

    yield put<OrganizationActionTypes>(updateOrganizationNotesSuccess());
    const flashMessage = `Success! Mediation has been ${mediationEnabled ? 'enabled' : 'disabled'} for this organisation.`;
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_ORG_MEDIATION_SETTINGS,
        message: flashMessage,
        state: FlashState.SUCCESS,
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_ORG_MEDIATION_SETTINGS, message: error, state: FlashState.ERROR })
    );
    yield put(updateOrganizationMediationSettingsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchOrganizationSagas() {
  yield takeLatest(FETCH_ORGANIZATION_BY_ID_API_REQUESTED, fetchOrganizationById);
  yield takeLatest(CREATE_ORGANIZATION_API_REQUESTED, createOrganization);
  yield takeLatest(CREATE_ORGANIZATION_INVITE_API_REQUESTED, createOrganizationInvite);
  yield takeLatest(updateOrganizationNotesRequested.type, updateOrganizationNotes);
  yield takeLatest(updateOrganizationMediationSettingsRequested.type, updateOrganizationMediationSettings);
}
